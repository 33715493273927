import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide54/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide54/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide54/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide54/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide54/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo los chatbots están revolucionando la atención en las Fintech
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Los chatbots son clave para mantener la eficiencia en el sector de servicios financieros. Estas herramientas, impulsadas por la inteligencia artificial, han permitido a las empresas Fintech ahorrar considerablemente en costos operativos y tiempo.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Según investigaciones de Juniper Research, la implementación de chatbots ha permitido un ahorro de 7.3 mil millones de dólares en costos operativos y alrededor de 826 millones de horas en interacciones con clientes.
    <br /><br />
    La adopción de servicios Fintech en Estados Unidos ha experimentado un crecimiento notable, pasando del 37% en 2020 al 48% en 2021 y alcanzando el 65% en 2022. Esta tendencia subraya el rápido avance digital en el sector de servicios financieros, impulsado por las expectativas de los consumidores nacidos en la era digital.
  </p>
)

const data = {
  start: {
    support: "Guía | Banca y finanzas",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatizar preguntas frecuentes.",
      },
      {
        key: 2,
        text: " Acceso a cuentas por medio de bots.",
      },
      {
        key: 3,
        text: "Ayuda en transacciones.",
      },
      {
        key: 4,
        text: "Bots para consejos financieros.",
      },
      {
        key: 4,
        text: "Uso de bots para prevención de fraudes.",
      }, 
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
